import { useContext, useMemo } from 'react'
import {
  AppointGuarantor,
  CreditApproved,
  ExistingRequests,
  FinancingSummary,
  GuarantorChecklist,
  IncompleteCCB,
  PreApproved,
  PreSelfie,
  ReviewFlow,
  WaitingGuarantorSignature,
} from '~/components/index'
import { LoadingFinanceSimulation } from '~/components/organisms/LoadingFinanceSimulation'
import { AddressScreen } from '~/components/templates/Checkout/AddressScreen'
import { BasicInfoScreen } from '~/components/templates/Checkout/BasicInfoScreen'
import { PaymentSelectionScreen } from '~/components/templates/Checkout/PaymentSelectionScreen'
import { SinglePageCheckout } from '~/components/templates/Checkout/SinglePageCheckout'
import {
  CheckoutFailureByMethod,
  CheckoutSuccessByMethod,
  ContractSummary,
  CreditInAnalysis,
  InvalidCheckout,
  PreRegistration,
  UnicoSelfieScreen,
  ValidateData,
  ValidateVerificationScreen,
  VerificationScreen,
} from '~/components/templates/Checkout/index'
import { CouponCode } from '~/components/templates/CreditPath/CouponCode'
import { CreditDeniedScreen } from '~/components/templates/CreditPath/CreditDenied'
import {
  FinancingSuccess,
  GuarantorAnalysis,
  GuarantorDenied,
  InsertDocument,
  IntroGuarantor,
  LoadingAnalysis,
  PreDocument,
} from '~/components/templates/CreditPath/index'
import { InScreenError } from '~/components/templates/InScreenError'
import { EndBasicInfoScreen } from '~/components/templates/Isa/EndBasicInfoScreen'
import { FormScreenN } from '~/components/templates/Isa/FormScreenN'
import { IntroISA } from '~/components/templates/Isa/IntroISA'
import { InvalidPath } from '~/components/templates/Isa/InvalidPath'
import { LoadingCPFAnalysis } from '~/components/templates/Isa/LoadingCPFAnalysis'
import { SelectProduct } from '~/components/templates/SelectProduct'
import { formScreenRegex } from '~/constants/formScreenRegex'
import { CheckoutContext } from '~/contexts/CheckoutContext'

interface IUseScreen {
  defaultScreen: JSX.Element
}

export const useScreen = ({ defaultScreen }: IUseScreen) => {
  const { step } = useContext(CheckoutContext)

  const renderStep = useMemo(() => {
    if (formScreenRegex.test(step)) {
      return <FormScreenN key={step} />
    }

    switch (step) {
      case 'singlePageCheckout':
        return <SinglePageCheckout />
      case 'preRegistration':
        return <PreRegistration />
      case 'basicInfo':
        return <BasicInfoScreen />
      case 'address':
        return <AddressScreen />
      case 'paymentSelection':
        return <PaymentSelectionScreen />
      case 'verificationScreen':
        return <VerificationScreen />
      case 'emailVerification':
      case 'checkoutEmailVerification':
        return <ValidateVerificationScreen verificationType="email" key={step} />
      case 'smsVerification':
        return <ValidateVerificationScreen verificationType="phone" key={step} />
      case 'reviewInfo':
        return <ValidateData />
      case 'invalidRequest':
        return <InvalidCheckout />
      case 'checkoutFail':
        return <CheckoutFailureByMethod />
      case 'checkoutSuccess':
        return <CheckoutSuccessByMethod />
      case 'guarantorChecklist':
        return <GuarantorChecklist />
      case 'guarantorAnalysis':
        return <GuarantorAnalysis />
      case 'appointGuarantor':
        return <AppointGuarantor />
      case 'introGuarantor':
        return <IntroGuarantor />
      case 'preSelfie':
        return <PreSelfie />
      case 'guarantorDenied':
        return <GuarantorDenied />
      case 'endBasicInfo':
        return <EndBasicInfoScreen />
      case 'preDocument':
        return <PreDocument />
      case 'insertCNH':
      case 'insertRGFront':
      case 'insertRGBack':
        return <InsertDocument />
      case 'incompleteBasicInfoAnalysis':
      case 'incompleteFinanceSimulation':
      case 'incompleteDocumentAnalysisTimeout':
      case 'documentAnalysis':
      case 'basicInfoAnalysis':
      case 'incompleteGuarantorAnalysisTimeout':
        return <LoadingAnalysis />
      case 'selfie':
        return <UnicoSelfieScreen />
      case 'preApproved':
        return <PreApproved />
      case 'contractSummary':
        return <ContractSummary />
      case 'financingSuccess':
        return <FinancingSuccess />
      case 'creditInAnalysis':
        return <CreditInAnalysis />
      case 'waitingGuarantorSignature':
        return <WaitingGuarantorSignature />
      case 'financingSummary':
        return <FinancingSummary />
      case 'creditApproved':
        return <CreditApproved />
      case 'existingRequests':
        return <ExistingRequests />
      case 'incompleteCCB':
        return <IncompleteCCB />
      case 'couponCode':
        return <CouponCode />
      case 'startReview':
      case 'endReview':
        return <ReviewFlow />
      case 'introISA':
        return <IntroISA />
      case 'selectProduct':
        return <SelectProduct />
      case 'invalidPath':
        return <InvalidPath />
      case 'loadingCPFAnalysis':
        return <LoadingCPFAnalysis />
      case 'loadingFinanceSimulation':
        return <LoadingFinanceSimulation />
      case 'creditDenied':
        return <CreditDeniedScreen />
      default:
        return defaultScreen
    }
  }, [step, defaultScreen])

  const inScreenError = useMemo(() => {
    return <InScreenError />
  }, [])

  const currentScreen = (
    <>
      {inScreenError}
      {renderStep}
    </>
  )

  return {
    currentScreen,
  }
}
